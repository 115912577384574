import Inputmask from 'inputmask';
const valid = require('card-validator');

export class CreditCardForm {

    constructor(element) {
        this.element = element;
        this.cardnumber = this.element.querySelector('input[name="cardnumber"]');
        this.ccexp = this.element.querySelector('input[name="cc-exp"]');
        this.cvc = this.element.querySelector('input[name="cvc"]');
        this.form = document.querySelector('form[data-cc-form="ok"]');
        this.promocode = this.form.querySelector('input[name="promocode"]');
        this.run();
    }

    setupUpsell() {

        document.querySelector('button[data-confirm-upsell="true"]').addEventListener('click', () => {
            document.querySelector('input[name="upsell"]').value = 'true';
            this.form.isUpselled = true;
            this.submit();
        });

        document.querySelector('button[data-confirm-upsell="false"]').addEventListener('click', () => {
            document.querySelector('input[name="upsell"]').value = 'false';
            this.form.isUpselled = true;
            this.submit();
        });

    }

    loading(state) {

        this.form.isSubmitted = state;
        const button = this.form.querySelector('button[type="submit"]');

        if (state) {
            button.disabled = true;
            if (!button.dataset.text) {
                button.dataset.text = button.innerHTML;
            }
            button.innerHTML = '<i class="fad fa-spinner fa-spin"></i>'
        } else {
            button.disabled = false;
            button.innerHTML = button.dataset.text;
        }
    }

    formSnapshot() {
        const data = new FormData(this.form);
        return Object.fromEntries(data.entries());
    }

    async submit() {

        if (this.form.isSubmitted || false) { return; }
        this.loading(true);
        const snapshot = this.formSnapshot();

        // Check for upsell first if applicable
        if (!(this.form.isUpselled || false)) {
            if (parseInt(snapshot.product) != 6) {
                this.setupUpsell();
                this.loading(false);
                document.querySelector('#modal-upsell').classList.add('is-active');
                return;
            }
        }

        // Always check for express shipping after upsell (if applicable)
        if (!(this.form.isExpressShipped || false)) {
            this.setupExpressShipping();
            this.loading(false);
            document.querySelector('#modal-express-shipping').classList.add('is-active');
            return;
        }

        // If we get here, both upsell and express shipping have been handled
        this.form.isSubmitted = true;
        this.loading(false);
        this.form.dispatchEvent(new Event('submit', { cancelable: true }));
    }

    setupExpressShipping() {
        document.querySelector('button[data-confirm-express="true"]').addEventListener('click', () => {
            document.querySelector('input[name="express_shipping"]').value = 'true';
            this.form.isExpressShipped = true;
            this.submit();
        });

        document.querySelector('button[data-confirm-express="false"]').addEventListener('click', () => {
            document.querySelector('input[name="express_shipping"]').value = 'false';
            this.form.isExpressShipped = true;
            this.submit();
        });
    }

    invalidCard() {
        alert(this.cardnumber.dataset.error);
        const icon = this.cardnumber.parentNode.querySelector('.card-icon');
        icon.innerHTML = '<i class="fal fa-credit-card"></i>';
        this.cardnumber.value = '';
        this.cardnumber.parentNode.querySelector('.check-icon').classList.add('is-hidden');
        this.cardnumber.classList.remove('is-success');
    }

    validCard(cardType) {
        this.cardnumber.parentNode.querySelector('.check-icon').classList.remove('is-hidden');
        this.cardnumber.classList.add('is-success');
        const icon = this.cardnumber.parentNode.querySelector('.card-icon');
        if (cardType == 'visa') {
            icon.innerHTML = '<i class="fab fa-cc-visa"></i>';
        }
        if (cardType == 'mastercard') {
            icon.innerHTML = '<i class="fab fa-cc-mastercard"></i>';
        }
    }

    validExpiration() {
        this.ccexp.parentNode.querySelector('.check-icon').classList.remove('is-hidden');
        this.ccexp.classList.add('is-success');
    }

    invalidExpiration() {
        alert(this.ccexp.dataset.error);
        this.ccexp.value = '';
        this.ccexp.parentNode.querySelector('.check-icon').classList.add('is-hidden');
        this.ccexp.classList.remove('is-success');
    }

    validCVC() {
        this.cvc.parentNode.querySelector('.check-icon').classList.remove('is-hidden');
        this.cvc.classList.add('is-success');
    }

    invalidCVC() {
        alert(this.cvc.dataset.error);
        this.cvc.value = '';
        this.cvc.parentNode.querySelector('.check-icon').classList.add('is-hidden');
        this.cvc.classList.remove('is-success');
    }

    run() {

        this.cardnumber.addEventListener('keyup', () => {
            window.startedCheckout = true;
        });

        this.promocode.addEventListener('keyup', (e) => {
            window.startedCheckout = true;
        });

        Inputmask('9999 9999 9999 9999', {
            'oncomplete': () => {
                const validate = valid.number(this.cardnumber.value);
                validate.isValid ? this.validCard(validate.card.type) : this.invalidCard();
            },
            'onincomplete': () => {
                this.invalidCard();
            }
        }).mask(this.cardnumber);

        Inputmask('99/99', {
            'oncomplete': () => {

                const exp = this.ccexp.value.split('/');
                const expMonth = parseInt(exp[0]);
                const expYear = parseInt(exp[1]);

                if (expMonth < 1 || expMonth > 12) {
                    this.invalidExpiration();
                } else {

                    const expDate = new Date((2000 + expYear), (expMonth), 0);
                    const today = new Date();

                    if (expDate >= today) {
                        this.validExpiration();
                    } else {
                        this.invalidExpiration();
                    }

                }

            },
            'onincomplete': () => {
                this.invalidExpiration();
            }
        }).mask(this.ccexp);

        Inputmask('999', {
            'oncomplete': () => {

                const cvc = parseInt(this.cvc.value);
                if (cvc >= 1 && cvc <= 999) {
                    this.validCVC();
                } else {
                    this.invalidCVC();
                }

            },
            'onincomplete': () => {
                this.invalidCVC();
            }
        }).mask(this.cvc);

        this.form.addEventListener('submit', (e) => {
            e.preventDefault();
            this.submit();
        });

    }

}