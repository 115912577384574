const axios = require('axios');

export class CreditCardFormNMI {

    validFields = {
        'ccnumber': false,
        'ccexp': false,
        'cvv': false
    };

    constructor(element) {
        this.element = element;
        this.form = document.querySelector('form[data-cc-form="ok"]');
        this.promocode = this.form.querySelector('input[name="promocode"]');
        this.run();
    }

    run() {
        console.log('NMI');

        const ccexpPlaceholder = this.element.dataset.placeholderCcexp;

        CollectJS.configure({
            "variant": "inline",
            "customCss": {
                "box-shadow": "inset 0 0.0625em 0.125em rgb(10 10 10 / 5%);",
                "background-color": "#ffffff",
                "border": "1px solid #dbdbdb",
                "border-radius": "4px",
                "color": "#363636",
                "font-family": "BlinkMacSystemFont, -apple-system, \"Segoe UI\", \"Roboto\", \"Oxygen\", \"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\", \"Helvetica\", \"Arial\", sans-serif;",
                "font-size": "1rem",
                "height": "2.5rem",
                "line-height": "1.5",
                "padding": "0.5rem"
            },
            "invalidCss": {
                "border-color": "#f14668"
            },
            "validCss": {
                "border-color": "#48c78e"
            },
            "fields": {
                "ccnumber": {
                    "selector": "#ccnumber",
                    "title": "",
                    "placeholder": "•••• •••• •••• ••••"
                },
                "ccexp": {
                    "selector": "#ccexp",
                    "placeholder": ccexpPlaceholder
                },
                "cvv": {
                    "display": "show",
                    "selector": "#cvv",
                    "placeholder": "•••"
                },
            },
            "validationCallback": (field, valid, message) => {
                this.validFields[field] = valid;
            },
            "fieldsAvailableCallback": () => {
                const frames = document.querySelectorAll('iframe.CollectJSInlineIframe');
                for (const frame of frames) {
                    frame.addEventListener('focus', (event) => {
                        window.startedCheckout = true;
                    })
                }
            },
            "callback": async (response) => {
                await this.nmiCallback(response);
            }
        });

        this.promocode.addEventListener('keyup', (e) => {
            window.startedCheckout = true;
        });

        this.form.addEventListener('submit', (e) => {
            e.preventDefault();
            this.submit();
        });

    }

    formSnapshot() {
        const data = new FormData(this.form);
        return Object.fromEntries(data.entries());
    }

    setupUpsell() {

        document.querySelector('button[data-confirm-upsell="true"]').addEventListener('click', () => {
            document.querySelector('input[name="upsell"]').value = 'true';
            this.form.isUpselled = true;
            this.submit();
        });

        document.querySelector('button[data-confirm-upsell="false"]').addEventListener('click', () => {
            document.querySelector('input[name="upsell"]').value = 'false';
            this.form.isUpselled = true;
            this.submit();
        });

    }

    loading(state) {

        this.form.isSubmitted = state;
        const button = this.form.querySelector('button[type="submit"]');

        if (state) {
            button.disabled = true;
            if (!button.dataset.text) {
                button.dataset.text = button.innerHTML;
            }
            button.innerHTML = '<i class="fad fa-spinner fa-spin"></i>'
        } else {
            button.disabled = false;
            button.innerHTML = button.dataset.text;
        }

        document.body.style.cursor = (state ? 'progress' : 'default');

    }

    async submit() {

        if (this.form.isSubmitted || false) { return; }
        this.loading(true);

        const frames = document.querySelectorAll('iframe.CollectJSInlineIframe');
        const frameCcnumber = frames[0];
        const frameCcexp = frames[1];
        const frameCvv = frames[2];

        if (!this.validFields.ccnumber) {
            alert(this.element.dataset.errorCcnumber);
            this.loading(false);
            frameCcnumber.focus();
            return;
        }

        if (!this.validFields.ccexp) {
            alert(this.element.dataset.errorCcexp);
            this.loading(false);
            frameCcexp.focus();
            return;
        }

        if (!this.validFields.cvv) {
            alert(this.element.dataset.errorCvc);
            this.loading(false);
            frameCvv.focus();
            return;
        }

        const snapshot = this.formSnapshot();

        // Check for upsell first if applicable
        if (!(this.form.isUpselled || false)) {
            if (parseInt(snapshot.product) != 6) {
                this.setupUpsell();
                this.loading(false);
                document.querySelector('#modal-upsell').classList.add('is-active');
                return;
            }
        }

        // Always check for express shipping after upsell (if applicable)
        if (!(this.form.isExpressShipped || false)) {
            this.setupExpressShipping();
            this.loading(false);
            document.querySelector('#modal-express-shipping').classList.add('is-active');
            return;
        }

        // New: check if required files are filled (input required shim)
        const requiredInputs = document.querySelectorAll('input[required]');
        const errorRequired = this.form.dataset.errorRequired;
        let forError = false;
        for (const requiredInput of requiredInputs) {
            const value = requiredInput.value.replace('_', '');
            if (value == '') {
                forError = true;
            }
        }
        if (forError) {
            alert(errorRequired);
            this.loading(false);
            return;
        }

        // All is good, let's tokenize the request!
        CollectJS.startPaymentRequest();

        return;
    }

    setupExpressShipping() {
        document.querySelector('button[data-confirm-express="true"]').addEventListener('click', () => {
            document.querySelector('input[name="express_shipping"]').value = 'true';
            this.form.isExpressShipped = true;
            this.submit();
        });

        document.querySelector('button[data-confirm-express="false"]').addEventListener('click', () => {
            document.querySelector('input[name="express_shipping"]').value = 'false';
            this.form.isExpressShipped = true;
            this.submit();
        });
    }

    async nmiCallback(response) {

        if (response.token) {
            document.querySelector('input[name="paymentId"]').value = response.token;

            const snapshot = this.formSnapshot();
            let request;

            try {
                request = await axios.post(`?auth`, snapshot);
            } catch (error) {
                alert(this.element.dataset.errorGeneric);
                this.loading(false);
                return;
            }

            const authResponse = request.data;
            const success = authResponse.success || false;
            const message = authResponse.message || false;

            if (!success) {
                alert(this.element.dataset.errorGeneric);
                this.loading(false);
                return;
            }

            const url = authResponse.url || false;
            if (url) {
                window.location.href = url;
                return;
            }

            alert(this.element.dataset.errorGeneric);
            this.loading(false);
            return;

            //this.form.submit();
            //return;
        }
        alert(this.element.dataset.errorGeneric);
        this.loading(false);
    }

}