const axios = require('axios');

export class PromoCode {

    constructor(element) {
        this.element = element;

        this.input = this.element.querySelector('input[name="promocode"]');
        this.alert = this.element.querySelector('div.alert-container');
        this.product = document.querySelectorAll('input[name="product"]');

        this.currentProductId = function () { return document.querySelector('input[name="product"]:checked').value; };

        this.timeout = null;
        this.run();
    }

    run() {

        const that = this;

        for (const elem of this.product) {
            elem.addEventListener('change', function () {
                that.checkPromoCode();
            });
        }

        this.element.addEventListener('keyup', function (e) {
            clearTimeout(this.timeout);
            this.timeout = setTimeout(() => {
                that.checkPromoCode();
            }, 300);
        });

    }

    async checkPromoCode() {
        const coupon = this.input.value;
        const productId = this.currentProductId();
        this.alert.innerHTML = '';
        if (coupon != '') {

            if (coupon.toUpperCase() == 'EXIT10') {
                const message = document.querySelector('#modal-exit').dataset.confirmMessage;
                if (window.enableExitCoupon === true) {
                    this.alert.innerHTML = `<div class="notification is-success mt-5">${message}</div>`;
                } else {
                    this.input.value = '';
                }
                return;
            }

            let response;
            try {
                response = await axios.get(`/api/coupon?coupon=${coupon}`);
            } catch (error) {
                return;
            }

            if (response && response.data && response.data.success) {
                const lang = window.lang;
                const message = response.data[`message_${lang}`] || false;
                const products = response.data.products || [];
                const validForProduct = products.indexOf(productId) !== -1;
                if (message) {
                    if (validForProduct) {
                        this.alert.innerHTML = `<div class="notification is-success mt-5">${message}</div>`;
                    } else {

                        this.input.value = '';
                        this.input.focus();

                        let warning = this.element.dataset.wrongProduct;
                        const template = this.element.dataset.template;
                        const templatePlural = this.element.dataset.templatePlural;

                        let prepare = [];
                        for (const product of products) {
                            const useTemplate = parseInt(product) > 1 ? templatePlural : template;
                            prepare.push(useTemplate.replace('{{ID}}', product));
                        }

                        warning += '<br>' + prepare.join('<br>');

                        this.alert.innerHTML = `<div class="notification is-warning mt-5">${warning}</div>`;
                        alert(warning);
                    }
                }
            } else {

                const warning = this.element.dataset.invalidCode;
                this.alert.innerHTML = `<div class="notification is-danger mt-5">${warning}</div>`;
                alert(warning);
            }

        }
    }

}